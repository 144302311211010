import React from "react"
import LayoutRegular from "../components/layoutRegular"
import StyledFullBackground from "../components/backgroundImage"
import SEO from "../components/seo"
import { Link } from "gatsby"

const pageSEO = {title: "About (deutsch)", description: "Benjamin Engeli fährt rund 1000 Kilometer Zug pro Woche und pendelt so zwischen seinen etwa 60 jährlichen Auftritten in den grossen Konzertsälen Europas, seiner Lehrtätigkeit am Vorarlberger Landeskonservatorium Feldkirch, seinen vielfältigen Jury-Aufgaben und seinem Engagement als Vater einer dreijährigen Tochter. Diese Vielseitigkeit widerspiegelt sich auch in seiner grossen stilistischen Bandbreite als Pianist."}

export default function AboutDe() {
  return (
    <StyledFullBackground imgName="alex">
        <LayoutRegular>    
        <SEO title={pageSEO.title} description={pageSEO.description} />
        <div className="content">
        
        <div className="flex-row-center">
          <h1>Biografie</h1>
          <div className="language-button slide-in mobile-only"><Link to="/about">in English</Link></div>
        </div>
        
        <div className="divider"></div>
          <p className="text fade-in">
            Benjamin Engeli fährt rund 1000 Kilometer Zug pro Woche und pendelt so zwischen seinen etwa 60 jährlichen Auftritten in den grossen Konzertsälen Europas, seiner Lehrtätigkeit an der Zürcher Hochschule der Künste, seinen vielfältigen Jury-Aufgaben und seinem Engagement als Familienvater. 
            Seine Vielseitigkeit widerspiegelt sich auch in seiner grossen stilistischen <Link to="/repertoire">Bandbreite</Link> als Pianist. Er konzertiert mit Orchestern wie dem Sinfonieorchester des Bayerischen Rundfunks, dem Tschaikowsky-Sinfonieorchester Moskau oder dem Tonhalle Orchester Zürich und hat mit zahlreichen CD-Produktionen mit Werken von Joseph Haydn über George Gershwin bis zur neuesten Musik auf sich aufmerksam gemacht.
            <br /><br />
            Benjamin Engeli widmet sich mit Begeisterung der Kammermusik: als Mitglied des <a href="https://de.wikipedia.org/wiki/Tecchler_Trio" target="_blank" rel="noreferrer">Tecchler Trios</a> gewann er 2007 den ARD-Musikwettbewerb in München, inzwischen ist er ein weltweit gefragter musikalischer Partner seiner Berufskollegen. Mit dem <a href="https://www.ensemblekandinsky.com/" target="_blank" rel="noreferrer">Ensemble Kandinsky</a> interpretiert er die Meisterwerke des 19. und 20. Jahrhunderts, durch sein Engagement im <a href="http://www.gershwinpianoquartet.com/" target="_blank" rel="noreferrer">Gershwin Piano Quartet</a> bewegt er sich gern zwischen stilistischen Grenzen und blickt auch als Arrangeur häufig über den Tellerrand der Klassik. Mit Uraufführungen und Kompositionsaufträgen setzt er sich darüber hinaus sehr für das zeitgenössische Musikschaffen der Schweiz ein. 
            Benjamin Engeli stammt aus einer Musikerfamilie und begann schon früh, sich für die verschiedensten Instrumente zu begeistern. Er studierte zuerst Horn, konzentrierte sich aber bald auf sein Hauptinstrument Klavier und wurde dabei zu einem grossen Teil von Adrian Oetiker an der Musikakademie Basel ausgebildet. Weitere Studien folgten bei Homero Francesch, Lazar Berman, Maurizio Pollini und Andràs Schiff.
            Er lebt mit seiner Familie in Aarau.

            {/* Benjamin Engeli fährt rund 1000 Kilometer Zug pro Woche und pendelt so zwischen seinen etwa 60 jährlichen Auftritten in den grossen Konzertsälen Europas, seiner Lehrtätigkeit am <a href="https://vlk.ac.at/das-konservatorium/lehrk%C3%B6rper/dozenten-detail/engeli_benjamin" target="_blank" rel="noreferrer">Vorarlberger Landeskonservatorium Feldkirch</a>, seinen vielfältigen Jury-Aufgaben und seinem Engagement als Vater einer dreijährigen Tochter. Diese Vielseitigkeit widerspiegelt sich auch in seiner grossen stilistischen <Link to="/repertoire">Bandbreite</Link> als Pianist. Er konzertiert mit Orchestern wie dem Sinfonieorchester des Bayerischen Rundfunks, dem Tschaikowsky-Sinfonieorchester Moskau oder dem Tonhalle Orchester Zürich und hat mit zahlreichen CD-Produktionen mit Werken von Joseph Haydn über George Gershwin bis zur neuesten Musik auf sich aufmerksam gemacht.
            <br /><br />Benjamin Engeli widmet sich mit Begeisterung der Kammermusik: als Mitglied des <a href="https://de.wikipedia.org/wiki/Tecchler_Trio" target="_blank" rel="noreferrer">Tecchler Trios</a> gewann er 2007 den ARD-Musikwettbewerb in München, inzwischen ist er Mitglied des <a href="https://www.ensemblekandinsky.com/" target="_blank" rel="noreferrer">Ensemble Kandinsky</a> und ein weltweit gefragter musikalischer Partner zahlreicher Berufskollegen. Durch sein Engagement im <a href="http://www.gershwinpianoquartet.com/" target="_blank" rel="noreferrer">Gershwin Piano Quartet</a> bewegt er sich gern zwischen stilistischen Grenzen und blickt auch als Arrangeur häufig über den Tellerrand der Klassik. Mit Uraufführungen und Kompositionsaufträgen setzt er sich darüber hinaus sehr für das zeitgenössische Musikschaffen der Schweiz ein. 
            Benjamin Engeli stammt aus einer Musikerfamilie und begann schon früh, sich für die verschiedensten Instrumente zu begeistern. Er studierte zuerst Horn, konzentrierte sich aber bald auf sein Hauptinstrument Klavier und wurde dabei zu einem grossen Teil von Adrian Oetiker an der Musikhochschule in Basel ausgebildet. Weitere Studien folgten bei Homero Francesch, Lazar Berman, Maurizio Pollini und Andràs Schiff. */}
          </p>
        </div>
        </LayoutRegular>
    </StyledFullBackground>
  )
}
